import Link from 'next/link'
import { useRouter } from 'next/router'
import Logo from './Logo'
import useT from '@hooks/useTranslation'

import b from '@constants/Business'
import {
    // FORUM_PAGE,
    CONTACT_PAGE,
    FIRM_BASE_PAGE,
    PRICING_PAGE,
    ABOUT_US_PAGE,
    PRIVACY_PAGE,
    REGULATIONS_PAGE,
    SALE_DOC_PAGE,
    COLISION_DOC_PAGE,
    SALE_BUY_DOC_PAGE,
    SITE_MAPS_PAGE,
    NEWS_PAGE,
    API_DOCS_PAGE,
    PUNKTA_PAGE,
} from '@constants/routes'

import { CAR_LINK_BASE } from '@constants/searchFormLinks'
import { DOC_TYPE_INFO } from '@constants/articles/docTypes'
import blogCategory from '@utils/links/blogCategory'

import styles from 'styles/Footer.module.css'

const HOST = process.env.NEXT_PUBLIC_HOST

const Footer = ({ Layout = FooterLayout }) => {
    return (
        <footer className={styles.footer}>
            <div className="container">
                <Layout />
            </div>
            <div className={styles.copyright}>
                <div className="container">
                {b.copyright} <span>Samochody.pl</span>
                </div>
            </div>
        </footer>
    )
}

function FooterLayout () {
    return (
        <FooterGrid>
            <FooterBrand />
            <FooterContact />
            <FooterNews />
            <FooterInformation />
        </FooterGrid>
    )
}

export function FooterGrid ({ children }) {
    return <div className={styles.grid}>{children}</div>
}

export function FooterBrand () {
    return (
        <div>
            <Logo bg="dark" />
            <SocialMedia />
        </div>
    )
}

function FooterContact () {
    return (
        <div>
            <h3>{useT('Kontakt')}</h3>
            <p><FooterLink to={CONTACT_PAGE}>{useT('Formularz kontaktowy')}</FooterLink></p>
            <p><Email email={b.email} /></p>

            {/* <p>Pomoc i obsługa klienta (8:00 - 16:00)</p>
            <p>570 970 160</p> */}

            {/* <p><Phone phone={b.phone} /></p>
            <p>{b.address}</p> */}
        </div>
    )
}

export function FooterInformation ({ nodocs }) {
    const router = useRouter()
    const reloadCookieSettings = () => {
        document.cookie = 'cook_consent=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        router.push('/')
    }

    return (
        <div>
            <h3>Samochody.pl</h3>
            <p>
                <FooterLink to={ABOUT_US_PAGE}>{useT('O nas')}</FooterLink>
            </p>
            <p>
                <FooterLink to={PRICING_PAGE}>{useT('Ceny ogłoszeń')}</FooterLink>
            </p>
            <p>
                <FooterLink to="/potwierdzenie-umowy-z-samochody-pl-instrukcje">{useT('Instrukcje potwierdzenia umowy z Samochody.pl')}</FooterLink>
            </p>
            <p>
                <FooterLink to={API_DOCS_PAGE}>{useT('API Samochody.pl')}</FooterLink>
            </p>
            <p>
                <FooterLink to={PRIVACY_PAGE}>{useT('Polityka prywatności')}</FooterLink>
            </p>
            <p>
                <FooterLink to={REGULATIONS_PAGE}>{useT('Regulamin serwisu')}</FooterLink>
            </p>
            <p>
                <FooterLink to={SITE_MAPS_PAGE}>{useT('Mapa strony')}</FooterLink>
            </p>
            {
                !nodocs && <p>
                    <a href="#" onClick={reloadCookieSettings}>{useT('Ustawienia ciasteczek')}</a>
                </p>
            }
        </div>
    )
}

export function FooterNews ({ nodocs }) {
    return (
        <div>
            <h3>{useT('Motoryzacja')}</h3>

            {/* <p>
                <Link href={FORUM_PAGE}><a>{useT('Forum samochodowe')}</a></Link>
            </p> */}

            <p>
                <FooterLink to={NEWS_PAGE}>{useT('Wiadomości motoryzacyjne')}</FooterLink>
            </p>

            <BlogCategories />

            <p>
                <FooterLink to={FIRM_BASE_PAGE}>{useT('Baza firm')}</FooterLink>
            </p>

            <p>
                <FooterLink to={[CAR_LINK_BASE,'uzywane'].join('/')}>{useT('Samochody używane')}</FooterLink>
            </p>

            <p>
                <FooterLink to={[CAR_LINK_BASE,'nowe'].join('/')}>{useT('Samochody nowe')}</FooterLink>
            </p>

            { !nodocs && <FooterDocs /> }

            <p>
                <FooterLink to={PUNKTA_PAGE}>{useT('Program partnerski Punkta')}</FooterLink>
            </p>
        </div>
    )
}

const CONSIDERED_CATEGORIES = Object
    .keys(DOC_TYPE_INFO)
    .filter(docType => ['tech-data'].includes(docType))

function BlogCategories () {
    return CONSIDERED_CATEGORIES
        .map(docType => {
            const { name } = DOC_TYPE_INFO[docType]
            const to = blogCategory({ docType })

            return <p key={docType}><FooterLink to={to}>{useT(name)}</FooterLink></p>
        })
}

export function FooterDocs ({ suffix = ` - ${useT('wzór')}` }) {
    return (
        <>
            <p><FooterLink to={SALE_DOC_PAGE}>{useT('Umowa kupna-sprzedaży') + suffix}</FooterLink></p>
            <p><FooterLink to={COLISION_DOC_PAGE}>{useT('Oświadczenie sprawcy kolizji') + suffix}</FooterLink></p>
            <p><FooterLink to={SALE_BUY_DOC_PAGE}>{useT('Zgłoszenie sprzedaży samochodu')}</FooterLink></p>
        </>
    )
}

function SocialMedia () {
    return (
        <div className={styles.social_media}>
            <SocialMediaIcon id="facebook" to={b.facebook} />
            <SocialMediaIcon id="twitter" to={b.twitter} />
            <SocialMediaIcon id="instagram" to={b.instagram} />
        </div>
    )
}

function SocialMediaIcon ({ id, to }) {
    return (
        <a title={id} href={to} target="_blank" rel="noopener noreferrer">
            <img src={`/svg/${id}.svg`} alt={id} width="28" height="28" />
        </a>
    )
}

function FooterLink ({ to, children }) {
    return (
        <Link href={`${HOST}${to}`}>
            <>
                {children}
            </>
        </Link>
    );
}

function Email ({ email }) {
    if (typeof email !== 'string') return null
    return (
        <Link href={`mailto:${email}`}>
            {email}
        </Link>
    );
}

export default Footer