
import Footer, { FooterGrid, FooterBrand, FooterInformation,
    FooterNews, FooterDocs } from '@components/shared/FooterLegacy'
import useT from '@hooks/useTranslation'

const SalonFooter = () => <Footer Layout={Layout} />

function Layout () {
    return (
        <FooterGrid>
            <FooterBrand />
            <FooterNews nodocs />
            <FooterDocuments />
            <FooterInformation nodocs />
        </FooterGrid>
    )
}

function FooterDocuments () {
    return (
        <div>
            <h3>{useT('Dokumenty do pobrania')}</h3>
            <FooterDocs />
        </div>
    )
}

export default SalonFooter