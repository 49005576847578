import Navbar from '@components/shared/Navbar'
import Footer from '@components/salon/SalonFooter'

import styles from 'styles/Main.module.css'

const SalonLayout = ({ children, navdown, nofooterdiv }) =>
    <main className={styles.main}>
        <Navbar logoOnly="true" navdown={navdown} />
        { children }
        <Footer nodiv={nofooterdiv} />
    </main>

function NavSpace (props) {
    return <div className={styles['nav-space']} {...props} />
}

SalonLayout.NavSpace = NavSpace

export default SalonLayout